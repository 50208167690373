<template>
  <v-sheet class="salary">
    <v-row class="mx-0">
      <v-col md="2" class="py-0">
        <label for="segment_name" class="custom-form-label" style="font-weight: 600">Month</label>
        <SelectInput
          hide-details
          :disabled="pageLoading"
          :loading="pageLoading"
          :items="['January', 'February', 'March', 'April']"
          id="segment"
          item-text="name"
          item-value="id"
          placeholder="Select Month"
        ></SelectInput>
      </v-col>
      <v-col class="mt-5">
        <v-row>
          <v-flex>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="blue darken-4"
                  v-on="on"
                  v-bind="attrs"
                  depressed
                  tile
                  class="ml-2 white--text me-1"
                  >Apply</v-btn
                >
              </template>
              <span>Apply</span>
            </v-tooltip>
          </v-flex>
        </v-row>
      </v-col>
      <v-col class="mt-5 d-flex justify-content-end">
        <v-btn class="ml-2" :disabled="pageLoading" depressed tile v-on:click="goBack()">
          Cancel
        </v-btn>
        <v-btn
          color="blue darken-4"
          v-on="on"
          v-bind="attrs"
          @click="savesalary"
          depressed
          tile
          class="ml-2 white--text me-1"
          ><v-icon class="me-2">print</v-icon>Save as Draft</v-btn
        >
      </v-col>
    </v-row>
    <SearchCriteria
      v-if="listingSearch"
      :search="listingSearch"
      search-string="Salary #, Full Name, Email, Phone No. and Address"
      v-on:close-search="resetSearch"
    ></SearchCriteria>

    <SalaryList :mode="mode" v-on:salary="updateSalary"></SalaryList>
  </v-sheet>
</template>
<script>
import SearchCriteria from "@/view/components/SearchCriteria";
import { GET_CUSTOMERS, GET_TEMPLATE_LIST } from "@/core/lib/customer.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import SalaryList from "@/view/components/SalaryList";
import {
  SET_SEARCH_TITLE,
  SET_SEARCH_MODEL,
  SET_SEARCH_BAR,
  BEFORE_DESTROY,
} from "@/core/services/store/listing.module";
import { mapGetters } from "vuex";
import { format } from "date-fns";
import { GET_AVAILABLE_VOUCHERS, GET_COLLECTED_VOUCHERS } from "@/core/lib/common.lib";
import SelectInput from "@/view/components/SelectInput.vue";

export default {
  name: "salary-listing",
  title: "Listing Salary",
  data() {
    return {
      mode: true,
      salarydata:[],
      template: "first",
      filterType: "all",
      filterDateRange: null,
      speedDial: [],
      whatsappPromotional: false,
      salaries: [],
      currentPage: 1,
      pageLimit: 1,
      showingFrom: 0,
      showingTo: 0,
      totalRows: 0,
      pageLoading: true,
      deleteText: null,
      deleteEndpoint: null,
      deleteDialog: false,
      salaryId: null,
      orderDialog: false,
      rOrderId: 0,
      rOrderCode: null,
      rLoading: false,
      rVouchers: [],
      redeemDialog: false,
      cOrderId: 0,
      cOrderCode: null,
      cLoading: false,
      cVouchers: [],
      collectDialog: false,
      allCollectedDialog: false,
      order: {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      },
    };
  },
  watch: {
    currentPage() {
      this.getSalary();
    },
    listingSearch() {
      this.getSalary();
    },
    filterDateRange() {
      if (this.filterDateRange && this.filterDateRange.length > 1) {
        this.filterType = "all";
        this.getSalary();
      }
    },
  },
  methods: {
    savesalary(){
      this.salary = this.salarydata;
      console.log(this.salary,'salary')

    },
    updateSalary(param){
      this.salarydata = param;
       console.log(param,'param')
    },
    getAllCollectedVouchers() {},
    // changeDatePicker() {
    //   if (this.filterDateRange[0] && this.filterDateRange[1]) {
    //     this.getSalary()
    //   }
    // },
    filterTypeChange() {
      this.filterDateRange = null;
      // this.getSalary();
    },
    saveOrder() {
      this.salaryId = null;
      this.order = {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      };
    },
    addOrder({ id }) {
      this.salaryId = id;
      this.orderDialog = true;
    },
    vCollected() {
      this.cOrderId = 0;
      this.cOrderCode = null;
      this.cVouchers = [];
      this.collectDialog = false;
      this.saveOrder();
    },
    vRedeemed() {
      this.rOrderId = 0;
      this.rOrderCode = null;
      this.rVouchers = [];
      this.redeemDialog = false;
      this.saveOrder();
    },
    async getAvailableVouchers(orderId, barcode) {
      try {
        this.cOrderId = orderId;
        this.cOrderCode = barcode;
        this.cLoading = true;
        this.cVouchers = await GET_AVAILABLE_VOUCHERS(orderId);
        this.collectDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.cLoading = false;
      }
    },
    async getCollectedVouchers(orderId, barcode) {
      try {
        this.rOrderId = orderId || 0;
        this.rOrderCode = barcode || null;
        this.rLoading = true;
        this.rVouchers = await GET_COLLECTED_VOUCHERS(this.salaryId);
        this.redeemDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.rLoading = false;
      }
    },
    routeToUpdate(id) {
      this.$router.push({
        name: "customer-update",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    routeToDetail(id) {
      this.$router.push({
        name: "customer-detail",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    deleteConfirm({ barcode, id }) {
      this.deleteText = barcode;
      this.deleteEndpoint = `customer/${id}`;
      this.deleteDialog = true;
    },
    async getSalary() {
      try {
        let form = {
          current_page: this.currentPage,
          search: this.listingSearch,
          filter_type: this.filterType,
        };
        if (this.filterDateRange) {
          const dateRange = {
            start_date: this.filterDateRange[0],
            end_date: this.filterDateRange[1],
          };
          form = { ...form, ...dateRange };
        }
        this.pageLoading = true;
        const { rows, totalPages, showingFrom, showingTo, totalRows } = await GET_CUSTOMERS(form);
        this.salaries = rows;
        this.pageLimit = totalPages;
        this.showingFrom = showingFrom;
        this.showingTo = showingTo;
        this.totalRows = totalRows;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
    resetSearch() {
      this.$router
        .replace({
          query: {
            t: new Date().getTime(),
          },
        })
        .then(() => {
          this.$store.dispatch(SET_SEARCH_MODEL, null);
        });
    },
    async getTemplateListing() {
      try {
        this.pageLoading = true;
        console.log("getTemplateListing");
        const data = await GET_TEMPLATE_LIST();
        console.log("data", data);
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
  },
  async mounted() {
    this.getSalary();
    this.$store.dispatch(SET_SEARCH_BAR, true);
    this.$store.dispatch(SET_SEARCH_TITLE, "Salary");
  },
  beforeDestroy() {
    this.$store.dispatch(BEFORE_DESTROY);
  },
  components: {
    SearchCriteria,
    SelectInput,
    SalaryList,
  },
  computed: {
    ...mapGetters(["listingSearch"]),
  },
};
</script>

<style>
.styled-table {
  border-collapse: collapse;
  width: 100%;
}

.styled-table th,
.styled-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.styled-table th {
  font-weight: bold;
}

.table_height_supplier .v-data-table__wrapper {
  height: calc(100vh - 255px);
}
::-webkit-scrollbar {
  height: 10px;
}
</style>
